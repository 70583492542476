<template>
  <div class="main">
    <van-row style="float:left;margin-top:10px;">
      <van-col span="10" offset="1">
        <van-image
          :src="require('../../../assets/logo.png')"
          style="width:100%"
        />
      </van-col>
      <van-col span="12" offset="1" class="headtext">申请体验</van-col>
    </van-row>
    <van-row style="float:left;margin-top:10px;">
      <van-col span="24">
        <!-- Field 是基于 Cell 实现的，可以使用 CellGroup 作为容器来提供外边框。 -->
        <van-cell-group>
          <van-field
            v-model="form.factoryName"
            label="工厂名称"
            placeholder="请输入工厂名称"
          />
        </van-cell-group>
      </van-col>
      <van-col span="24">
        <!-- Field 是基于 Cell 实现的，可以使用 CellGroup 作为容器来提供外边框。 -->
        <van-cell-group>
          <van-field
            v-model="form.userName"
            label="联系人"
            placeholder="请输入联系人"
          />
        </van-cell-group>
      </van-col>
      <van-col span="24">
        <!-- Field 是基于 Cell 实现的，可以使用 CellGroup 作为容器来提供外边框。 -->
        <van-cell-group>
          <van-field
            v-model="form.phone"
            label="联系电话"
            placeholder="请输入联系电话"
          />
        </van-cell-group>
      </van-col>
      <van-col span="24">
        <!-- Field 是基于 Cell 实现的，可以使用 CellGroup 作为容器来提供外边框。 -->
        <van-cell-group>
          <van-field
            v-model="form.address"
            rows="2"
            type="textarea"
            placeholder="请输入联系地址"
            show-word-limit
          />
        </van-cell-group>
      </van-col>
    </van-row>

    <div class="nav">
      <van-button
        type="success"
        @click="experiences"
        style="display:block;margin:auto;width:95%;"
        >申请体验</van-button
      >
    </div>
  </div>
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup, Dialog } from "vant";
import { Field } from "vant";
import { Button } from "vant";
import { Image as VanImage } from "vant";
import { application } from "@/config/_api";
import { Overlay } from "vant";
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [VanImage.name]: VanImage,
    [Field.name]: Field,
    [Button.name]: Button,
    [Overlay.name]: Overlay,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      form: {
        factoryName: "",
        userName: "",
        address: "",
        phone: "",
      },
      code: "",
    };
  },
  created() {},
  methods: {
    returns() {
      this.$router.push("login");
    },
    async experiences() {
      if (
        this.form.factoryName == "" ||
        this.form.userName == "" ||
        this.form.address == "" ||
        this.form.phone == ""
      ) {
        Dialog.alert({
          title: "提示",
          message: "请输入完善申请体验信息",
        }).then(() => {
          // on close
        });
      } else {
        let { data } = await application({
          ...this.form,
        });
        // console.log(data);
        // let { token, userInfo } = data.data;
        // Toast.success(data.msg);
        Dialog.alert({
          title: "提示",
          message: data.msg,
        }).then(() => {
          // on close
        });
        if (data.code != 0) {
          this.$router.push("login");
        }
      }
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  height: 100vh;
  background: #fff;
}
.headtext {
  display: flex;
  align-items: center;
}
.nav {
  float: left;
  margin-top: 10px;
  display: block;
  width: 100%;
}
#formData {
  width: 90%;
  height: 30%;
  background-color: #fff;
  position: relative;
}
.form_bottom {
  width: 100%;
  height: 14%;
  position: absolute;
  border-top: 1px solid #c7c3c3;
  bottom: 0;
}
.form_main {
  width: 100%;
  height: 80%;
  padding: 5%;
}
</style>
